<template>
  <v-row class="fill-height calendar-fullscreen">
    <v-col class="pa-0">
      <v-sheet height="64">
        <v-toolbar flat>
          <v-btn icon @click="$emit('close')" class="mr-4">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
            Today
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="prev">
            <v-icon small>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="next">
            <v-icon small>
              mdi-chevron-right
            </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <div style="width: 15rem;">
            <v-select
              v-model="selectedUser"
              :items="users"
              :item-text="(item) => `${item.firstname} ${item.lastname}`"
              :item-value="(item) => item"
              label="Select User"
              outlined
              dense
              hide-details
              :disabled="!isEncadrement"
            ></v-select>
          </div>

          <v-spacer></v-spacer>
          <v-menu bottom right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right>
                  mdi-menu-down
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>Day</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Week</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Month</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = '4day'">
                <v-list-item-title>4 days</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-sheet class="flex-grow-1" style="height: calc(100vh - 64px)">
        <v-calendar
          v-for="user in selectedUsersCalendars"
          :key="user.id"
          ref="calendar"
          color="primary"
          :events="user.events"
          :event-color="getEventColor"
          :type="type"
          :weekdays="[1, 2, 3, 4, 5]"
          first-interval="8"
          interval-count="12"
          interval-height="48"
          :value="focus"
          :event-margin-bottom="3"
          :event-ripple="false"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @input="updateFocus"
          event-overlap-mode="stack"
          :event-overlap-threshold="30"
          :event-text-color="getEventTextColor"
          @change="updateRange"
        ></v-calendar>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-y
          top
          min-width="290px"
        >
          <v-card color="grey lighten-4" flat>
            <v-toolbar height="44" :color="selectedEvent.color" dark>
              <v-btn
                icon
                @click="openModalUpdate(selectedEvent)"
                v-if="rightsDev"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
              <v-spacer></v-spacer>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-card>
                      <v-list-item v-bind="attrs" v-on="on">
                        <v-icon color="primary" class="mr-2"
                          >mdi-state-machine</v-icon
                        >
                        <v-list-item-title class="purple--text"
                          >Changer le statut</v-list-item-title
                        >
                      </v-list-item>
                    </v-card>
                  </template>
                  <v-list>
                    <v-list-item @click="statusChange('prise_en_compte')">
                      <v-list-item-title>Prise en compte</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="statusChange('en_cours')">
                      <v-list-item-title>En cours</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="statusChange('terminé')">
                      <v-list-item-title>Terminé</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-menu>
            </v-toolbar>
            <v-card-text>
              <!-- l'heure -->
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    label="Début souhaité"
                    :value="selectedEvent.start | formatDate"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Fin souhaitée"
                    :value="selectedEvent.end | formatDate"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-divider></v-divider>
                <!-- details en readOnly -->
                <v-col cols="12">
                  <v-text-field
                    label="Details"
                    :value="selectedEvent.details | escapeHTML"
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn text color="secondary" @click="selectedOpen = false">
                Fermer
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
    </v-col>
    <!-- modale d edition -->
    <v-dialog v-model="editModal" max-width="50%">
      <EditDev @close-edit="editModal = false" />
    </v-dialog>
  </v-row>
</template>

<script>
import UserService from "@/Services/UserService";
import DeveloppementService from "@/Services/SupportVision/DeveloppementService";
import * as Vuex from "vuex";
import moment from "moment";

export default {
  components: {
    EditDev: () => import("@/Components/Views/SupportVision/Cartes/editDev"),
  },
  props: {
    resourceId: {
      type: [String, Number],
      default: null,
    },
    resourceName: {
      type: String,
      default: "",
    },
    isEncadrement: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    users: [],
    editModal: false,
    selectedUser: null,
    focus: moment().format("YYYY-MM-DD"),
    type: "month",
    typeToLabel: {
      month: "Month",
      week: "Week",
      day: "Day",
      "4day": "4 Days",
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
  }),
  filters: {
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY - HH:mm");
    },
    // echappempent des balises html
    escapeHTML(value) {
      if (value) {
        return value.replace(/<[^>]*>/g, "");
      } else {
        return "";
      }
    },
  },
  computed: {
    ...Vuex.mapState(["informations", "taskSupportVision", "rightsDev"]),
    ...Vuex.mapMutations(["setTaskId", "setTaskSupportVision"]),
    // Obtenir les calendriers des utilisateurs sélectionnés

    calendarTitle() {
      return this.selectedUserFullName
        ? `Calendar - ${this.selectedUserFullName}`
        : "Calendar";
    },

    // Modifie selectedUsersCalendars pour retourner le bon format pour v-calendar
    selectedUsersCalendars() {
      if (!this.selectedUser) return [];

      const calendarId =
        typeof this.selectedUser === "object"
          ? this.selectedUser.id
          : this.selectedUser;

      return [
        {
          id: calendarId,
          focus: this.focus,
          events: this.events,
          calendar: this.selectedUserFullName,
        },
      ];
    },
    selectedUserFullName() {
      if (
        this.selectedUser &&
        this.selectedUser.firstname &&
        this.selectedUser.lastname
      ) {
        return `${this.selectedUser.firstname} ${this.selectedUser.lastname}`;
      }
      return "";
    },
    getColorByDemande() {
      return (demande) => {
        if (demande == "Travaux unique") {
          return "green";
        } else if (demande == "Release") {
          return "blue";
        } else if (demande == "Urgent") {
          return "red";
        }
      };
    },
    getColorByStatus() {
      return (status) => {
        if (status == "prise_en_compte") {
          return "blue";
        } else if (status == "en_cours") {
          return "orange";
        } else if (status == "terminé") {
          return "green";
        }
      };
    },
  },
  watch: {
    // selectedUser: 'loadEventsForSelectedUser', // Chargez les événements pour l'utilisateur sélectionné lorsqu'il change
    // charger les evenements à chaque fois que les tâches sont modifiées depuis le store
    taskSupportVision: "loadEventsForSelectedUser",
    focus: {
      handler(newVal) {
        if (this.$refs.calendar && this.$refs.calendar[0]) {
          this.$refs.calendar[0].scrollToTime("08:00");
        }
      },
    },
    resourceName: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          // Chercher l'utilisateur correspondant dans la liste des utilisateurs
          const selectedUserObj = this.users.find(
            (user) => `${user.firstname} ${user.lastname}` === newVal
          );

          if (selectedUserObj) {
            this.selectedUser = selectedUserObj;
            this.loadEventsForSelectedUser();
          }
        }
      },
    },
    selectedUser: "loadEventsForSelectedUser",
    events: {
      immediate: true,
      deep: true,
      handler(newEvents) {
        if (this.$refs.calendar && this.$refs.calendar[0]) {
          this.$nextTick(() => {
            this.$refs.calendar[0].checkChange();
          });
        }
      },
    },
  },
  async mounted() {
    await this.loadUsers();
    await this.loadEventsForSelectedUser(); // Chargez les événements au démarrage
  },
  methods: {
    // Chargement des utilisateurs
    async loadUsers() {
      try {
        const res = await UserService.getUsers();
        this.users = res;

        // Si on a un resourceName (utilisateur cliqué), on le sélectionne
        if (this.resourceName) {
          const selectedUserObj = this.users.find(
            (user) => `${user.firstname} ${user.lastname}` === this.resourceName
          );
          if (selectedUserObj) {
            this.selectedUser = selectedUserObj;
          }
        }
      } catch (error) {
        console.error("Erreur lors du chargement des utilisateurs:", error);
      }
    },
    updateRange({ start, end }) {
      this.loadEventsForSelectedUser();
    },
    getEventTextColor(event) {
      return "#FFFFFF"; // ou utilisez une logique basée sur la couleur de fond
    },
    // Chargement des événements pour l'utilisateur sélectionné
    async loadEventsForSelectedUser() {
      if (!this.selectedUser) return;

      // Construire le nom complet de l'utilisateur sélectionné
      const selectedUserFullName =
        typeof this.selectedUser === "object"
          ? `${this.selectedUser.firstname} ${this.selectedUser.lastname}`
          : this.resourceName; // Utiliser resourceName si selectedUser n'est pas un objet

      // Filtrer les données qui ont été affectées
      const data = this.taskSupportVision.filter(
        (item) => item.carte?.assignement !== undefined
      );

      // Liste des devs affectés avec vérification supplémentaire
      const userAffected = data
        .filter((item) => item.carte?.assignement?.assignedTo)
        .map((item) => item.carte.assignement.assignedTo);

      // Vérifiez si le dev sélectionné est dans la liste des devs affectés
      const hasAssignedTasks = userAffected.includes(selectedUserFullName);
      if (hasAssignedTasks) {
        this.events = data
          .filter(
            (item) =>
              item.carte?.assignement?.assignedTo === selectedUserFullName
          )
          .map((item) => {
            if (
              item.carte?.assignement?.dateDebut &&
              item.carte?.assignement?.dateFin
            ) {
              return {
                name: item.carte.libelle || "Sans titre",
                details: item.carte.description || "",
                start: item.carte.assignement.dateDebut,
                end: item.carte.assignement.dateFin,
                color:
                  this.getColorByDemande(item.carte.impactType) || "primary",
                id: item._id,
                timed: false,
              };
            }
            return null;
          })
          .filter((event) => event !== null);

      } else {
        this.events = [];
      }
    },
    async statusChange(status) {
      // Mettre à jour le statut de la tâche et envoyer toute la tache et ajouter le statut
      let task = this.selectedEvent;

      task.status = status;
      // ajouter la date et l'heure du changement de statut
      task.dateStatusChange = moment().format("YYYY-MM-DDTHH:mm");
      await DeveloppementService.editDev(task.id, task);
      this.loadEventsForSelectedUser();
      this.selectedOpen = false;
      const res = await DeveloppementService.getDev();
      this.$store.commit("setTaskSupportVision", res.data);
    },
    updateFocus(newFocus) {
      this.focus = newFocus; // Mettre à jour la valeur de focus
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = moment().format("YYYY-MM-DD");
    },
    prev() {
      const currentDate = moment(this.focus);
      switch (this.type) {
        case "month":
          this.focus = currentDate.subtract(1, "month").format("YYYY-MM-DD");
          break;
        case "week":
          this.focus = currentDate.subtract(1, "week").format("YYYY-MM-DD");
          break;
        case "day":
          this.focus = currentDate.subtract(1, "day").format("YYYY-MM-DD");
          break;
        case "4day":
          this.focus = currentDate.subtract(4, "days").format("YYYY-MM-DD");
          break;
      }
    },
    next() {
      const currentDate = moment(this.focus);
      switch (this.type) {
        case "month":
          this.focus = currentDate.add(1, "month").format("YYYY-MM-DD");
          break;
        case "week":
          this.focus = currentDate.add(1, "week").format("YYYY-MM-DD");
          break;
        case "day":
          this.focus = currentDate.add(1, "day").format("YYYY-MM-DD");
          break;
        case "4day":
          this.focus = currentDate.add(4, "days").format("YYYY-MM-DD");
          break;
      }
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = {
          ...event,
          start: moment(event.start).format("YYYY-MM-DD HH:mm"),
          end: moment(event.end).format("YYYY-MM-DD HH:mm"),
        };
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            this.selectedOpen = true;
          });
        });
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => {
          requestAnimationFrame(() => open());
        });
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    async openModalUpdate(event) {
      this.editModal = true;
      this.$store.commit("setTaskId", event.id);
    },
  },
};
</script>
<style scoped>
.calendar-fullscreen {
  height: 100vh !important;
  margin: 0;
}

.calendar-height {
  height: 100% !important;
}

/* Pour s'assurer que le calendrier prend tout l'espace disponible */
.v-sheet {
  display: flex;
  flex-direction: column;
}

.v-calendar {
  flex: 1;
}
</style>
